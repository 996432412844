import apollo from "@/plugins/apollo";
import { GET_CHANNELS, GET_CHANNELS_IN_SITE, CREATE_CHANNEL,UPDATE_CHANNEL, GET_CHANNELS_IN_CAMPAIGN, DELETE_CHANNEL } from "@/graphql/Dashboard/Channel/Channels";

const getChannels = async (variables) => {
  const response = await apollo.query({
    query: GET_CHANNELS,
    variables,
  });
  
  return response.data.channels;
};

const getChannelsInSite = async (variables) => {
  const response = await apollo.query({
    query: GET_CHANNELS_IN_SITE,
    variables,
    fetchPolicy: 'no-cache',
  });
  
  return response.data.channels;
};

const getChannelsInCampaign = async (variables) => {
  const response = await apollo.query({
    query: GET_CHANNELS_IN_CAMPAIGN,
    variables,
  });
  
  return response.data.site.channels;
};

const createChannel = async (content) => {
  let payload = { content: content }
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CHANNEL,
      variables: payload
    })
    return response.data.createChannel.data
  } catch (error) {
    console.log(error.message)
  }
}

const updateChannel = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CHANNEL,
      variables: payload
    })
    
    return response.data.updateChannel.data
  } catch (error) {
    console.log(error.message)
  }
}

const deleteChannel = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: DELETE_CHANNEL,
      variables: payload
    })
    
    return response.data.deleteChannel.data
  } catch (error) {
    console.log(error.message)
  }
}

export { getChannels, getChannelsInSite, createChannel, getChannelsInCampaign, updateChannel, deleteChannel };
