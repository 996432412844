import gql from 'graphql-tag'

export const UPDATE_CONTACT_GROUP_SITE = gql`
  mutation Mutation($content: SiteContactsGroupContent!) {
    updateSiteContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
export const UPDATE_CONTACT_GROUP_ADVERTISER = gql`
  mutation UpdateAdvertiserContactsGroup($content: AdvertiserContactsGroupContent!) {
    updateAdvertiserContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
export const UPDATE_CONTACT_GROUP_ACCOUNT = gql`
  mutation UpdateAccountContactsGroup($content: AccountContactsGroupContent!) {
    updateAccountContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
