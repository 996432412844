import gql from 'graphql-tag'

export const GET_SITES = gql`
  query Sites($filter: String, $orderby: String, $direction: String, $offset: Int, $limit: Int) {
    sites(
      filter: $filter
      orderby: $orderby
      direction: $direction
      offset: $offset
      limit: $limit
    ) {
      site_active
      site_id
      site_name
      formats {
        format_id
        format_name
        format_size_height
        format_size_width
      }
      sites_contacts {
        contact_name
      }
    }
  }
`
export const GET_SITES_CAMPAIGN = gql`
  query Campaign($campaignId: Int!, $filter: String, $orderby: String, $limit: Int, $direction: String, $sitesFilter2: String) {
  campaign(filter: $filter) {
    sites(orderby: $orderby, limit: $limit, direction: $direction, filter: $sitesFilter2) {
      site_id
      site_name
      purchases(campaign_id: $campaignId) {
        cpc {
          quantity
        }
        cpm {
          quantity
        }
        cpv {
          quantity
        }
      }
      total_data(campaign_id: $campaignId) {
        clicks
        impressions
        views
      }
    }
  }
}
`
export const GET_SITES_IN_CAMPAIGN = gql`
  query Campaign(
    $filter: String
    $orderby: String
    $limit: Int
    $direction: String
  ) {
    campaign(filter: $filter) {
      sites(orderby: $orderby, limit: $limit, direction: $direction) {
        site_id
        site_name
      }
    }
  }
`
