import gql from 'graphql-tag'
export const DELETE_CONTACT_GROUP_SITE = gql`
  mutation DeleteSiteContactsGroup($content: SiteContactsGroupContent!) {
    deleteSiteContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
export const DELETE_CONTACT_GROUP_ACCOUNT = gql`
  mutation DeleteAccountContactsGroup($content: AccountContactsGroupContent!) {
    deleteAccountContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
export const DELETE_CONTACT_GROUP_ADVERTISER = gql`
  mutation DeleteAdvertiserContactsGroup($content: AdvertiserContactsGroupContent!) {
    deleteAdvertiserContactsGroup(content: $content) {
      code
      data
      message
      success
    }
  }
`
