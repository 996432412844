import gql from 'graphql-tag'

export const GET_CONTACTS_GROUPS_SITE = gql`
  query GET_CONTACTS_GROUPS_SITE($filter: String) {
    site(filter: $filter) {
      sites_contacts_groups {
        contact_group_is_default
        contact_group_name
        site_contact_group_id
        site_id
        team_id
      }
    }
  }
`

export const GET_CONTACTS_GROUPS_ADVERTISE = gql`
  query GET_CONTACTS_GROUPS_ADVERTISE($filter: String) {
    advertiser(filter: $filter) {
      accounts_contacts_groups {
        account_id
        contact_group_name
        account_contact_group_id
        team_id
      }
    }
  }
`

export const GET_CONTACTS_GROUPS_ACCOUNT = gql`
  query GET_CONTACTS_GROUPS_ACCOUNT($filter: String) {
    account(filter: $filter) {
      accounts_contacts_groups {
        account_contact_group_id
        account_id
        contact_group_name
        team_id
      }
    }
  }
`
