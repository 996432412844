import { getChannels, createChannel, getChannelsInCampaign, updateChannel, deleteChannel } from "@/services/channel.service"

export default {
	namespaced: true,
    state: {
        channels: [],
        channel: {},
    },
    getters: {
		channels: (state) => state.channels,
		channel: (state) => state.channel,
	},
    mutations: {
        SET_CHANNEL(state, payload){
            state.channel = payload
        },

        SET_CHANNELS(state, payload){
            state.channels = payload
        },

        UPDATE_CHANNEL(state, payload){
            const index = state.channels.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.channels[index] = payload;
            }
        },
        DELETE_CHANNEL(state, payload){
            state.channels = state.channels.filter(channel => channel.channel_id != payload.channel_id);
        },

        CREATE_CHANNEL(state, payload){
            state.channels.push(payload)
        },
    },
    actions: {
        async getChannels({ commit }, data){
            const res = await getChannels(data)
            
            commit("SET_CHANNELS", res)
        },
        async getChannelsInCampaign({ commit }, data){
            const res = await getChannelsInCampaign(data)
            
            commit("SET_CHANNELS", res)
        },

        async storeChannels({ commit }, data){
            const res = await createChannel(data)
            
            commit("CREATE_CHANNEL", res)
            commit("SET_CHANNEL", res)
            return res
        },

        async updateChannel({ commit }, data){
            const res = await updateChannel(data)

            commit("UPDATE_CHANNEL", res)
            return res
        },

        async findChannel({ commit }, data){
            const res = await getChannels(data)
            commit("SET_CHANNEL", res[0])
        },

        async deleteApiChannel({ commit }, data){
            await deleteChannel(data)
            commit("DELETE_CHANNEL", data.channel_id)
        }
    },
}

