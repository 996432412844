import { getSites, getSitesInCampaign, getSitesInCampaignList, registerSite, findSite, updateSite } from '@/services/site.service'

export default {
  namespaced: true,
  state: {
    vehicle: {},
    vehicles: [],
    vehiclesInCampaign: [],
    vehiclesList: [],
    vehiclesSelect: [],
  },
  getters: {
    vehicle: (state) => state.vehicle,
    vehicles: (state) => state.vehicles,
    vehiclesInCampaign: (state) => state.vehiclesInCampaign,
    vehiclesList: (state) => state.vehiclesList,
    vehiclesSelect: (state) => state.vehiclesSelect,
  },
  mutations: {
    SET_VEHICLE(state, payload) {
      state.vehicle = payload
    },

    SET_VEHICLE_IN_CAMPAIGN_LIST(state, payload) {
      state.vehiclesList = payload
      if(payload){
        state.vehiclesSelect = payload.map(el=> el.site_id)
      }
    },

    SET_VEHICLE_IN_CAMPAIGN(state, payload) {
      state.vehiclesInCampaign = payload
    },

    SET_NEW_VEHICLE_IN_CAMPAIGN_SELECT(state, payload) {
      state.vehiclesSelect = payload
    },

    SET_VEHICLES(state, payload) {
      state.vehicles = payload
    },

    UPDATE_VEHICLE(state, payload) {
      const index = state.vehicles.findIndex((task) => task.id === payload.id)
      if (index !== -1) {
        state.vehicles[index] = payload
      }
    },

    CREATE_VEHICLES(state, payload) {
      state.vehicles.push(payload)
    }
  },
  actions: {
    /* 
            TO-DO
            verificar se realmete é preciso paginar pois hj chamamos todos
        */
    async getVehicles({ commit }, data) {
      /* var variables = {
                limit: -1,
                offset: 0
                orderby: "site_id",
                direction: "desc"
            } */
      const res = await getSites(data)

      const result = res.map((item) => {
        return {
          site_id: item?.site_id,
          site_name: item?.site_name,
          name: `${item?.site_id} | ${item?.site_name}`,
          site_contacts: item?.sites_contacts?.length,
          formats: item?.formats
        }
      })

      commit('SET_VEHICLES', result)
    },

    async storeVehicles({ commit }, data) {
      const res = await registerSite(data)

      commit('CREATE_VEHICLES', res)
      commit('SET_VEHICLE', res)
      return res
    },

    async update({ commit }, data) {
      const res = await updateSite(data)

      commit('UPDATE_VEHICLE', res)
      return res
    },

    async findVehicle({ dispatch, commit }, data) {
      var variables = {
        filter: `{ 'site_id': ${parseInt(data)}}`
      }
      const res = await findSite(variables)
      // dispatch('contact/setHasId',{site_id:res.site_id}, { root: true })
      // dispatch('format/setFormats', res.formats, { root: true })
      // dispatch('contact/setContacts', res.sites_contacts, { root: true })
      // dispatch('contact/setGroupsContacts', res.sites_contacts_groups.map(i=>
      //     {
      //         return {...i,
      //     group_id: i.site_contact_group_id}
      // }), { root: true })
      commit('SET_VEHICLE', res)
    },

    async findVehicleInCampaign({ commit, state },id) {
      var variables = {
        filter: `{'campaign_id': ${parseInt(id)}}`,
        limit: -1,
        campaignId: parseInt(id),
        orderby: "site_name",
        direction: "asc",
        sitesFilter2: `{'sites.site_id':[${state.vehiclesSelect}]}`
      }
      const res = await getSitesInCampaign(variables)
      let resFormat = []
      if(res){
        resFormat = res.map(el=>{
          return {
            ...el,
            show: false
          }
        })
      }
      commit('SET_VEHICLE_IN_CAMPAIGN', resFormat)
    },

    async findVehicleInCampaignList({ commit }, data) {
      const res = await getSitesInCampaignList(data)
      commit('SET_VEHICLE_IN_CAMPAIGN_LIST', res)
    },

    async addVehicleInCampaignSelect({ commit }, data) {
      commit('SET_NEW_VEHICLE_IN_CAMPAIGN_SELECT', data)
    }
  }
}
