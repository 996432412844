import apollo from '@/plugins/apollo'
import { CREATE_CONTACT_ACCOUNT } from '@/graphql/Dashboard/Contact/ContactAccount'
import { CREATE_CONTACT_ADVERTISER } from '@/graphql/Dashboard/Contact/ContactAdvertiser'
import { CREATE_CONTACT_SITE } from '@/graphql/Dashboard/Contact/ContactSite'

import { UPDATE_CONTACT_ACCOUNT } from '@/graphql/Dashboard/Contact/UpdateContactAccount'
import { UPDATE_CONTACT_ADVERTISER } from '@/graphql/Dashboard/Contact/UpdateContactAdvertiser'
import { UPDATE_CONTACT_SITE } from '@/graphql/Dashboard/Contact/UpdateContactSite'

import { DELETE_CONTACT_ACCOUNT } from '@/graphql/Dashboard/Contact/DeleteContactAccount'
import { DELETE_CONTACT_ADVERTISER } from '@/graphql/Dashboard/Contact/DeleteContactAdvertiser'
import { DELETE_CONTACT_SITE } from '@/graphql/Dashboard/Contact/DeleteContactSite'

import { CREATE_CONTACT_GROUP_ACCOUNT } from '@/graphql/Dashboard/Contact/ContactGroupAccount'
import { CREATE_CONTACT_GROUP_ADVERTISER } from '@/graphql/Dashboard/Contact/ContactGroupAdvertiser'
import { CREATE_CONTACT_GROUP_SITE } from '@/graphql/Dashboard/Contact/ContactGroupSite'
import { 
  GET_CONTACTS_GROUPS_SITE, 
  GET_CONTACTS_GROUPS_ACCOUNT, 
  GET_CONTACTS_GROUPS_ADVERTISE 
} from '@/graphql/Dashboard/Contact/GetContactsGroups'
import { 
  UPDATE_CONTACT_GROUP_SITE,
  UPDATE_CONTACT_GROUP_ACCOUNT,
  UPDATE_CONTACT_GROUP_ADVERTISER
} from '@/graphql/Dashboard/Contact/UpdateContactGroup'
import { 
  DELETE_CONTACT_GROUP_SITE,
  DELETE_CONTACT_GROUP_ACCOUNT,
  DELETE_CONTACT_GROUP_ADVERTISER
} from '@/graphql/Dashboard/Contact/DeleteContactGroup'
import { 
  GET_CONTACTS_SITE,
  GET_CONTACTS_ACCOUNT,
  GET_CONTACTS_ADVERTISE
 } from '@/graphql/Dashboard/Contact/GetContacts'

const registerContactAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_ADVERTISER,
    variables: content
  })

  return response.data.createAdvertiserContact.data
}

const registerContactSite = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_SITE,
    variables: content
  })

  return response.data.createSiteContact.data
}

const fetchContactsSites = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_SITE,
    variables,
    fetchPolicy: 'no-cache',
  })
  return response.data.site.sites_contacts
}

const fetchContactsAccount = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_ACCOUNT,
    variables,
    fetchPolicy: 'no-cache',
  })
  return response.data.account.accounts_contacts
}

const fetchContactsAdvertise = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_ADVERTISE,
    variables,
    fetchPolicy: 'no-cache',
  })
  return response.data.advertiser.accounts_contacts
}

const fetchContactsGroupsSite = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_GROUPS_SITE,
    variables,
    fetchPolicy: 'no-cache',
  })
  return response.data.site.sites_contacts_groups
}
const fetchContactsGroupsAccount = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_GROUPS_ACCOUNT,
    variables,
    fetchPolicy: 'no-cache',
  })
  return response.data.account.accounts_contacts_groups
}
const fetchContactsGroupsAdvertise = async (variables) => {
  const response = await apollo.query({
    query: GET_CONTACTS_GROUPS_ADVERTISE,
    variables
  })
  return response.data.advertiser.accounts_contacts_groups
}

const updateContactGroupSite = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_GROUP_SITE,
    variables: content
  })
  return response.data
}

const updateContactGroupAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_GROUP_ACCOUNT,
    variables: content
  })
  return response.data
}

const updateContactGroupAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_GROUP_ADVERTISER,
    variables: content
  })
  return response.data
}

const deleteContactGroupSite = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_GROUP_SITE,
    variables: content
  })
  return response
}

const deleteContactGroupAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_GROUP_ACCOUNT,
    variables: content
  })
  return response
}

const deleteContactGroupAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_GROUP_ADVERTISER,
    variables: content
  })
  return response
}

const registerContactAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_ACCOUNT,
    variables: content
  })

  return response.data.createAccountContact.data
}

const registerContactGroupAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_GROUP_ADVERTISER,
    variables: content
  })

  return response.data.createAdvertiserContactsGroup.data
}

const registerContactGroupSite = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_GROUP_SITE,
    variables: content
  })

  return response.data.createSiteContactsGroup.data
}

const registerContactGroupAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: CREATE_CONTACT_GROUP_ACCOUNT,
    variables: content
  })

  return response.data.createAccountContactsGroup.data
}

const updateContactAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_ADVERTISER,
    variables: content
  })

  return response.data.updateAdvertiserContact.data
}

const updateContactSite = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_SITE,
    variables: content
  })

  return response.data.updateSiteContact.data
}

const updateContactAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CONTACT_ACCOUNT,
    variables: content
  })

  return response.data.updateAccountContact.data
}

const deleteContactAdvertiser = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_ADVERTISER,
    variables: content
  })

  return response.data.deleteAdvertiserContact
}

const deleteContactSite = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_SITE,
    variables: content
  })

  return response.data.deleteSiteContact
}

const deleteContactAccount = async (content) => {
  const response = await apollo.mutate({
    mutation: DELETE_CONTACT_ACCOUNT,
    variables: content
  })

  return response.data.deleteAccountContact
}

export {
  fetchContactsSites,
  fetchContactsAccount,
  fetchContactsAdvertise,
  deleteContactGroupSite,
  deleteContactGroupAccount,
  deleteContactGroupAdvertiser,
  updateContactGroupSite,
  updateContactGroupAccount,
  updateContactGroupAdvertiser,
  fetchContactsGroupsSite,
  fetchContactsGroupsAccount,
  fetchContactsGroupsAdvertise,
  registerContactAdvertiser,
  registerContactSite,
  registerContactAccount,
  registerContactGroupAdvertiser,
  registerContactGroupSite,
  registerContactGroupAccount,
  updateContactAdvertiser,
  updateContactAccount,
  updateContactSite,
  deleteContactAdvertiser,
  deleteContactSite,
  deleteContactAccount
}
