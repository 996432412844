import gql from 'graphql-tag'

export const GET_CONTACTS_SITE = gql`
  query Site($filter: String) {
    site(filter: $filter) {
      sites_contacts {
        site_contact_id
        contact_name
        contact_email
        contact_phone
        contact_groups_ids
        contact_whatsapp
        contact_obs
        contact_active
        contact_code
      }
    }
  }
`

export const GET_CONTACTS_ADVERTISE = gql`
  query GET_CONTACTS_ADVERTISE($filter: String) {
    advertiser(filter: $filter) {
      accounts_contacts {
        account_contact_id
        contact_name
        contact_email
        contact_phone
        contact_groups_ids
        contact_active
        contact_code
      }
    }
  }
`

export const GET_CONTACTS_ACCOUNT = gql`
  query GET_CONTACTS_ACCOUNT($filter: String) {
    account(filter: $filter) {
      accounts_contacts {
        account_contact_id
        contact_name
        contact_email
        contact_phone
        contact_groups_ids
        contact_active
        contact_code
      }
    }
  }
`
