import { getAgencys, findAgency, registerAgency, updateAgency } from "@/services/agency.service"

export default {
	namespaced: true,
    state: {
        agencys: [],
        agency: {},
    },
    getters: {
		agencys: (state) => state.agencys,
		agency: (state) => state.agency,
	},
    mutations: {
        SET_AGENCY(state, payload){
            state.agency = payload
        },

        UPDATE_AGENCY(state, payload){
            const index = state.agencys.findIndex(task => task.id === payload.id);
            if (index !== -1) {
                state.agencys[index] = payload;
            }
        },

        SET_AGENCYS(state, payload){
            state.agencys = payload
        },
        
        CREATE_AGENCYS(state, payload){
            state.agencys.push(payload)
        },
    },
    actions: {
        async getAgencys({ commit }, data){
            /* 
                TO-DO
                verificar se realmete é preciso paginar pois hj chamamos todos
            */
            var variables = {
                limit: -1,
                /* orderby: "account_id",
                direction: "desc" */
            }
            const res = await getAgencys(data)
            
            const result = res.map(item =>{
                return {
                    account_id: item?.account_id,
                    account_name: item?.account_name,
                    contacts: item?.accounts_contacts?.length,
                }
            })
            
            commit("SET_AGENCYS", result)
        },
        
        async store({ commit }, data){
            const res = await registerAgency(data)
            
            commit("CREATE_AGENCYS", res)
            commit("SET_AGENCY", res)
            return res
        },
        
        async update({ commit }, data){
            const res = await updateAgency(data)
            
            commit("UPDATE_AGENCY", res)
            return res
        },

        async find({ dispatch,commit }, data){
            var variables = {
                filter: `{ 'account_id': ${parseInt(data)}}`
              }
            const res = await findAgency(variables)
            dispatch('contact/setHasId',{account_id:res.account_id}, { root: true })
            dispatch('contact/setContacts', res.accounts_contacts, { root: true })
            dispatch('contact/setGroupsContacts', res.accounts_contacts_groups.map(i=> 
                {
                    return {...i,
                group_id: i.account_contact_group_id}
            }), { root: true })
            commit("SET_AGENCY", res)
        },
        async findAgency({ commit }, data){
            const res = await findAgency(data)
            
            commit("SET_AGENCY", res)
        },
    },
}

